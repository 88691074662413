"use client";

import mixpanel from "mixpanel-browser";
import { EnvService } from "@/services/Environment/Environment";

const TOKEN = "c8ea0da8a31fb303f333773b560df179";

export enum ANALYTICS_EVENTS {
  INTEGRATIONS_CONNECT_ACCOUNT = "INTEGRATIONS_CONNECT_ACCOUNT",
  INTEGRATIONS_DISCONNECT_ACCOUNT = "INTEGRATIONS_DISCONNECT_ACCOUNT",
  PAGE_VIEW = "PAGE_VIEW",
  TEMPLATES_USE_NEW = "TEMPLATES_USE_NEW",
  WORKFLOW_CREATE_NEW = "WORKFLOW_CREATE_NEW",
  WORKFLOW_OPEN = "WORKFLOW_OPEN",
  WORKFLOW_DUPLICATE = "WORKFLOW_DUPLICATE",
  WORKFLOW_EDIT = "WORKFLOW_EDIT",
  WORKFLOW_DELETE = "WORKFLOW_DELETE",
  WORKFLOW_OPEN_RUN_MODAL = "WORKFLOW_OPEN_RUN_MODAL",
  WORKFLOW_RUN_START = "WORKFLOW_RUN_START",
  WORKFLOW_OPEN_PAST_RUN = "WORKFLOW_OPEN_PAST_RUN",
  WORKFLOW_ADVANCED_CONFIGS_TOGGLED = "WORKFLOW_ADVANCED_CONFIGS_TOGGLED",
  WORKFLOW_RESTORE = "WORKFLOW_RESTORE",
  DOCS_MODULE_OPEN = "DOCS_MODULE_OPEN",
  WORKFLOW_ADD_MODULE = "WORKFLOW_ADD_MODULE",
  WORKFLOW_ADD_CONNECTION = "WORKFLOW_ADD_CONNECTION",
  WORKFLOW_DELETE_MODULE = "WORKFLOW_DELETE_MODULE",
  DOCS_OPEN_FROM_EDITOR = "DOCS_OPEN_FROM_EDITOR",
  PREVIOUS_RUNS_FILTER = "PREVIOUS_RUNS_FILTER",
  TRIGGERS_NEW = "TRIGGERS_NEW",
  TRIGGERS_EDIT = "TRIGGERS_EDIT",
  TRIGGERS_DELETE = "TRIGGERS_DELETE",
  TRIGGERS_DELETE_ALL = "TRIGGERS_DELETE_ALL",
  TRIGGERS_DELETE_BANNER_OPEN = "TRIGGERS_DELETE_BANNER_OPEN",
  API_KEY_DELETE = "API_KEY_DELETE",
  CHAT_RESOURCES_CLICK = "RESOURCES_DOCUMENTATION",
  CHAT_MESSAGE_EDIT = "MESSAGE_EDIT",
  CHAT_MESSAGE_EDIT_SEND = "CHAT_MESSAGE_EDIT_SEND",
  CHAT_MESSAGE_RETRY = "CHAT_MESSAGE_RETRY",
  CHAT_MESSAGE_SEND = "CHAT_MESSAGE_SEND",
  CHAT_MESSAGE_REGENERATE = "CHAT_MESSAGE_REGENERATE",
  HOME_CHAT_MODEL_SELECT = "HOME_CHAT_MODEL_SELECT",
  HOME_CHAT_OPEN_MODEL_SELECT = "HOME_CHAT_OPEN_MODEL_SELECT",
  HOME_FLOW_CLICK = "HOME_FLOW_CLICK",
  HOME_TEMPLATE_CLICK = "HOME_TEMPLATE_CLICK",
  HOME_VIEW_ALL_CLICK = "HOME_VIEW_ALL_CLICK",
  HOME_NEW_CHAT = "HOME_NEW_CHAT",
  SIDENAV_CONVERSATION_OPEN = "SIDENAV_CONVERSATION_OPEN",
  SIDENAV_CONVERSATION_DELETE = "SIDENAV_CONVERSATION_DELETE",
  FLOWS_TAGS_CREATE = "FLOWS_TAGS_CREATE",
  FLOWS_LABELS_EDIT = "FLOWS_LABELS_EDIT",
  FLOWS_LABELS_DELETE = "FLOWS_LABELS_DELETE",
  FLOWS_TAGS_UPDATE = "FLOWS_TAGS_UPDATE",
}

const BLACKLIST_EMAILS = ["pedroallenrevez@gmail.com"];
let current_email = "";

export const initMixpanel = (user_id: string) => {
  current_email = user_id;
  mixpanel.init(TOKEN);
  mixpanel.identify(user_id);
};

export const analytics_track = (
  event: ANALYTICS_EVENTS,
  options?: { [key: string]: any }
) => {
  const environment = EnvService.getVar("DEPLOYMENT_ENV");

  if (
    environment !== "prod" ||
    BLACKLIST_EMAILS.includes(current_email) ||
    current_email.endsWith("@spot-technologies.com") ||
    current_email.endsWith("@noxus.ai")
  )
    return;
  try {
    mixpanel.track(event, options);
  } catch (e) {
    console.error("Error logging on mixpanel");
  }
};
